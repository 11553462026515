<div>
  <div class="close">
    <mat-icon [mat-dialog-close]="false" [ngClass]="{ disabled: isLoading}">close</mat-icon>
  </div>
  <div mat-dialog-header class="dark">
    <h2>{{ data.heading }}</h2>
  </div>

  @if (data.requireCode) {
    <div mat-dialog-content>
      <div [innerHtml]="data['body'] | replace : '%%RESIDENT_FULLNAME%%' : data.residentFullName" class="mb-4"></div>
      <form class="flex flex-col items-center mb-4" (keydown.enter)="$event.preventDefault()">
        <mat-form-field appearance="outline" class="w-3/4">
          <input matInput [formControl]="code" [type]="showCode ? 'text' : 'password'" autocomplete="off" placeholder="Enter unlock code" tabindex="1">
          <mat-error>{{ getErrorMessages() }}</mat-error>
          <button type="button" mat-icon-button matSuffix (click)="showCode = !showCode" [attr.aria-label]="'Hide code'"
            [attr.aria-pressed]="showCode">
            <mat-icon id="show-password-icon">{{showCode ?
              'visibility' : 'visibility_off'}}</mat-icon>
          </button>
        </mat-form-field>
      </form>
    </div>
    <div mat-dialog-actions class="flex flex-col items-center ">
      <app-submit-button (onClick)="verifyCode()" [isLoading]="isLoading" tabindex="2">
        @if (!isLoading) {
          Unlock
        } @else {
          Unlocking
        }
      </app-submit-button>
    </div>
  } @else {
    <div mat-dialog-content>
      <div [innerHtml]="data['super-admin-unlock-body'] | replace : '%%RESIDENT_FULLNAME%%' : data.residentFullName" class="mb-4"></div>
    </div>
    <div mat-dialog-actions class="flex flex-col items-center ">
      <app-submit-button (onClick)="unlock()" [isLoading]="isLoading" tabindex="2">
        @if (!isLoading) {
          Unlock
        } @else {
          Unlocking
        }
      </app-submit-button>
    </div>
  }
</div>